@use 'styles/theme/theme' as theme;

.root {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid theme.$borderGrey;
  max-width: 180px;
  overflow: auto;
}

.separator {
  border-top: 1px solid theme.$secondaryGrey;
  margin: 8px 12px;
}

.typeList {
  & > div:last-child {
    .chip {
      font-size: 12px;
    }
  }
  > div:is(:last-child) > div:last-child {
    display: none;
  }
}

.loader {
  display: flex !important;
  padding: 12px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid theme.$borderGrey;
  background-color: theme.$orangeLight;
  padding: 7px 12px;
  overflow: hidden;
  grid-gap: 8px;
  cursor: pointer;

  &__label {
    display: flex;
    align-items: center;
    grid-gap: 3px;
    @media only screen and (max-width: 900px) {
      span {
        font-size: 12px;
      }
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    height: 26px;
    width: 26px;
    padding: 4px;
    border-radius: 50%;
    background-color: theme.$primaryGrey;
    svg {
      fill: theme.$white;
    }
  }
}

.loadMore {
  background-color: theme.$backgroundGrey2;
  border: 1px solid theme.$borderGrey;
  margin-top: 8px;
  margin: 0 auto;
  display: block;

  &.loadMoreInline {
    border-radius: 6px !important;
    border: 1px solid  theme.$borderGrey !important;
    background: theme.$orangeLight !important;
    padding: 8px 10px !important;
    gap: 0px !important;
    line-height: 1;
  }
}

.renderFacets {
  position: relative;
  &__inline {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.chip {
  background-color: theme.$orangeLight;
  padding: 12px 12px 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-left: 4px solid transparent;
  height: 38px;
  grid-gap: 6px;
  border-right: 1px solid theme.$borderGrey;

  &:hover {
    border-left: 4px solid theme.$orange;
  }

  &.active {
    border-left: 4px solid theme.$orange;
    border-right: 1px solid transparent;
    background-color: theme.$white;
    .chip__label {
      font-weight: theme.$font-weight-bold;
    }
  }

  &.disabled {
    color: theme.$tertiaryGrey;
    pointer-events: none;
    svg {
      fill: theme.$tertiaryGrey;
    }
  }

  &__label {
    flex-grow: 1;
  }
  &__applied {
    background-color: theme.$red;
    border-radius: 50%;
    padding: 2px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
  }
  &__lock {
    width: 14px;
    min-width: 14px;
    fill: theme.$primaryGrey;
  }
  &__icon {
    width: 20px;
    min-width: 20px;
  }

  &.inlineChip {
    border-radius: 6px !important;
    border: 1px solid theme.$borderGrey !important;
    background: theme.$orangeLight !important;
    padding: 12px 10px 12px 12px !important; 
  }
  
}

.inline {
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  position: relative;
  border: none;
  border-radius: 0;

  & > div {
    display: flex;
  }

  .inline {
    flex-wrap: nowrap;
  }

  .header {
    border-bottom: none;
    border-radius: 28px;
    flex-shrink: 0;
    border: 1px solid theme.$primaryGrey;
    background-color: theme.$orangeLight;
    padding: 8px 10px 8px 5px;
    height: 34px;
    margin-right: 6px;
    width: initial;

    &:hover {
      background: theme.$orangeLight !important;
      border: 1px solid theme.$orange !important;
      span {
        color: theme.$orange;
      }

      svg {
        fill: theme.$orange;
      }
    }

    &__label {
      &_text {
        color: theme.$primaryGrey;
        padding: 4px 0px 4px 0px;
      }
      &_textApplied {
        margin-right: 4px;
      }
    }

    &__icon {
      background-color: transparent;
      svg {
        fill: theme.$primaryGrey;
      }
    }

    &__active {
      background-color: theme.$orangeLight;
      border: 1px solid theme.$orange;
      color: theme.$orange;
      svg {
        fill: theme.$orange;
      }
    }

    &.headerFilterApplied {
      background-color: theme.$orangeLight !important;
      border: 1px solid theme.$primaryGrey !important;
      &:hover {
        .header__icon {
          svg {
            fill: theme.$primaryGrey;
          }
        }
        .header__label_text {
          color: theme.$primaryGrey !important;
        }
        .chip__applied {
          color: theme.$white;
        }
      }
    }

    &.inlineHeader {
      border-radius: 6px !important;
      border: 1px solid  theme.$borderGrey !important;
      background: theme.$orangeLight !important;
      padding: 8px 10px !important;
      gap: 0px !important;
    }
  }

  .chip {
    border-radius: 28px;
    padding: 12px;
    border: 1px solid theme.$tertiaryGrey;
    white-space: nowrap;
    height: 34px;
    background: theme.$white;

    &_icon {
      margin-left: 4px;
    }

    &:hover {
      .chip_icon {
        fill: theme.$orange;
      }
    }
  
    &.active {
      background-color: theme.$orangeLight;
    }
  }

  .overflowItemWrapper {
    margin: 0 6px;

    @media (max-width: 600px) {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.since {
  display: flex;
  background-color: theme.$white;
  &__title {
    display: flex;
    width: 171px;
    margin-top: 12px;
    padding: 10px 12px 10px 12px;
    align-items: center;
    gap: 8px;
    background-color: theme.$white;
    border-left: 4px solid transparent;
    justify-content: space-between;
    &_active {
      background: theme.$blueLight;
      border-left: 4px solid theme.$blue;
    }
    &:hover {
      background: theme.$blueLight;
      border-left: 4px solid theme.$blue;
    }
    cursor: pointer;
  }
}

.filterPopover {
  background-color: theme.$white;
  margin: 0 2px;
  border-radius: 18px;
  overflow: hidden;
  width: 270px;
  height: 499px;
}

.filterModal {
  max-width: 462px;

  @media screen and (max-width: 1024px) {
    height: 550px;
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-bottom: 1px solid theme.$tertiaryGrey;
    &_isSticky {
      position: sticky;
      top: 0;
      z-index: 2;
      background: theme.$white;
    }
    &_filterText {
      display: flex;
      align-items: center;
      gap: 8px;
      .text {
        color: theme.$orange;
      }

      .icon {
        fill: theme.$orange;
        font-weight: theme.$font-weight-medium;
      }
    }
    &_close {
      line-height: 1;
      .icon {
        fill: theme.$primaryGrey !important;
      }
    }
  }

  &__selectedInfo {
    padding: 17px 16px;
    border-bottom: 1px solid theme.$tertiaryGrey;
    &_isSticky {
      position: sticky;
      top: 47.5px;
      z-index: 2;
      background: theme.$white;
      &_hasMultiRole {
        padding: 8px 16px;
      }
    }
    p,
    span {
      font-size: theme.$font-size-14;
      text-transform: uppercase;
    }

    .scrollLeftIndicator,
    .scrollRightIndicator {
      cursor: pointer;
      height: 36px;
      margin-top: -1px;
      border: 1px solid theme.$borderGrey;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .scrollLeftIndicator {
      border: transparent;
      border-right: 1px solid theme.$secondaryGrey;
      box-shadow: 3px -0.5px 5px 0 rgba(0, 0, 0, 0.2);

      svg {
        margin-left: 7px;
      }
    }

    .scrollRightIndicator {
      border: transparent;
      border-left: 1px solid theme.$secondaryGrey;
      box-shadow: -3px -0.5px 5px 0 rgba(0, 0, 0, 0.2);
      svg {
        margin-right: 7px;
      }
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 171px auto;
    height: 50vh;
    min-height: 450px;
    &_fullHeight {
      height: 100vh;
    }
    &_left {
      padding-bottom: 52px;
      background-color: theme.$orangeLight;
      overflow: auto;
      .facets {
        padding-top: 12px;
        padding-bottom: 24px;
        background-color: theme.$orangeLight;
        .title {
          display: block;
          padding: 0 12px 12px 16px;
        }
      }
      .workRoles {
        background-color: theme.$white;
        border-right: 1px solid theme.$borderGrey;
        position: relative;
        padding-bottom: 24px;
        &::after {
          content: '';
          display: flex;
          justify-content: center;
          border-bottom: 1px solid theme.$secondaryGrey;
          border-right: 1px solid theme.$borderGrey;
          background-color: theme.$secondaryGrey;
          width: calc(100% - 32px);
          position: absolute;
          bottom: 0;
          left: 16px;
          margin-bottom: 16px;
        }
      }
    }
    &_right {
      overflow: hidden;
    }
    @media only screen and (max-width: 900px) {
      height: 100%;
    }
  }

  .header {
    border-bottom: none;
    padding: 0;
    background-color: theme.$white;
  }
}

.filterDrawerPaper {
  height: 90%;
  max-height: none;
}

.filterDrawerContent {
  height: calc(100% - 60px);
}

.filterBtn {
  height: 34px;
  padding: 8px 12px !important;
  font-size: 12px;
  font-weight: 700;
  min-width: unset;
  border-radius: 28px;
  background-color: theme.$orangeLight !important;
  &:hover {
    background-color: theme.$orangeLight !important;
    color: theme.$orange !important;
    text-decoration: underline;
    box-shadow: none !important;
    border: 1px solid theme.$orange !important;
    svg {
      fill: theme.$orange !important;
    }
  }
  &:active {
    background-color: theme.$orangeLight !important;
    box-shadow: none !important;
    text-decoration: none;
    color: theme.$orange !important;
    border: 1px solid theme.$orange !important;
    svg {
      fill: theme.$orange !important;
    }
  }

  &__count {
    background-color: theme.$red;
    border-radius: 50%;
    padding: 1px;
    height: 16px;
    width: 16px;
    display: inline-block;
    text-align: center;
    margin-left: 3px !important;
    text-decoration: none;
  }
}

.filterBtn__count:hover {
  text-decoration: none;
}

.filterDrawer {
  @media only screen and (max-width: 1000px) {
    max-height: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .closeIcon {
    display: none;
  }
  .inline {
    .header {
      flex-direction: row;
      padding: 8px 5px 8px 10px;
    }
  }

  .filterModal {
    &__header,
    &__selectedInfo {
      position: sticky;
      z-index: 1;
      background: theme.$white;
    }
    &__header {
      top: 0;
    }
    &__selectedInfo {
      top: 47.5px;
    }
  }
}