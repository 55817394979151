@use 'styles/theme/theme';

.title {
  margin-bottom: 30px;
}

.expandMoreIcon {
  fill: theme.$secondaryGrey !important;
}

.expandLessIcon {
  fill: theme.$blue !important;
}

.option {
  border-bottom: 1px solid theme.$borderGrey;

  &:last-child {
    border-bottom: none;
  }

  &__mobileListingView {
    border-bottom: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__titleBtn {
    width: 100%;
    justify-content: space-between;
    padding: 12px;
  }

  &__mobileListingViewTitleBtn {
    padding: 16px;
  }

  &__count {
    &::before {
      content: '•';
      color: theme.$secondaryGrey;
      padding: 0px 2px;
      font-size: 14px;
    }
  }
}

.roleType {
  display: flex;
  flex-direction: column;
  &__title {
    padding: 12px 12px 0 16px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 12px;
    cursor: pointer;
    border-left: 4px solid transparent;
    &:hover {
      background: theme.$blueLight;
      border-left: 4px solid theme.$blue;
    }

    .label {
      white-space: break-spaces;
    }

    .roleTypeCounts {
      display: flex;
      align-items: center;
      gap: 6px;
      .appliedCount {
        background-color: theme.$red;
        display: flex;
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        border-radius: 29px;
        line-height: 16px;
        color: theme.$white;
      }
      .total {
        margin-left: 4px;
      }
    }
  }
  &__itemActive {
    background: theme.$blueLight;
    border-left: 4px solid theme.$blue;
  }
}

.rolesListingWrapper {
  padding: 16px;
}

.rolesOptions {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  label {
    width: 100%;
  }

  &__searchInput {
    padding: 16px 0;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1px;

    &:hover {
      .rolesOptions__item_label {
        color: theme.$blue;
        text-decoration: underline;
        font-weight: theme.$font-weight-medium;
      }
      .rolesOptions__item_count {
        color: theme.$blue;
      }
    }

    span {
      display: block;
      padding: 9px 0;
      cursor: pointer;
    }
    &_count {
      margin-left: 4px;
    }
  }

  &__radioInput {
    width: 14px !important;
    height: 14px !important;
  }

  &__radioWrapper {
    &:hover {
      background: transparent !important;
    }
  }
}

.rolesNameListing {
  .filterDropdown__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 16px;
    background-color: theme.$white;
    z-index: 2;
    border-top: 1px solid theme.$tertiaryGrey;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

.submitSearch {
  flex: 1;
}

.expandedListContainer {
  margin-top: 24px;
}

.expandedList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 150px;

  &__title {
    margin-top: 14px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    row-gap: 10px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 12px;
    cursor: pointer;

    &:first-child {
      padding-top: 5px;
    }

    &:hover {
      color: theme.$blue;
      border-left: 2px solid theme.$blue;

      .expandedList__item_name {
        color: theme.$blue;
        text-decoration: underline;
      }

      .expandedList__item_arrowIcon {
        visibility: visible;
        fill: theme.$blue;
      }

      .expandedList__item_role {
        color: theme.$primaryGrey;
      }

      .expandedList__infoIcon {
        visibility: visible;
      }
    }

    &_name {
      max-width: 60%;
    }

    &_role {
      white-space: nowrap;
    }

    &_countSection {
      display: inline-flex;
      align-items: flex-start;
    }

    &_arrowIcon {
      visibility: hidden;
      margin-top: -2px;
      @media screen and (max-width: 1000px) {
        margin-left: 8px;
        fill: theme.$borderGrey;
        visibility: visible;
      }
    }
  }

  &__listingViewItem {
    padding: 0;
    &:first-child {
      padding-top: 0;
    }
    &:hover {
      border-left: none;
    }
  }

  &__mobileListingViewItem {
    padding: 8px 16px;
    &:first-child {
      padding-top: 8px;
    }
  }

  .accordion {
    margin-left: -8px;
    margin-top: 32px;
    gap: 8px;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid theme.$borderGrey;

    &__title {
      padding: 12px;
    }

    &__subTitle {
      padding: 0px 12px 12px 12px;
      display: inline-block;
      border-bottom: 1px solid theme.$borderGrey;
    }

    &__content {
      padding: 8px 12px;
    }

    &__collapsedItem {
      margin-top: -8px;
    }
  }

  &__stats {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    column-gap: 12px;

    @media screen and (max-width: 1000px) {
      margin-top: 0;
    }
  }

  &__infoIcon {
    margin-top: -4px;
    margin-right: 4px;
    visibility: hidden;
  }
}

.workRoleHeader {
  padding: 20px 16px 16px 16px;
}

.workRoleSection {
  display: flex;
  position: sticky;
  top: 0;
  background-color: theme.$white;
}

.workRoleSectionSticky {
  padding-top: 16px;
  padding-bottom: 12px;
}

.statsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: wrap;
  gap: 8px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid theme.$borderGrey;

  @media screen and (max-width: 1000px) {
    margin: 24px 16px;
  }

  &__icon {
    fill: theme.$tertiaryGrey !important;
  }

  &__separator {
    &::after {
      content: '•';
      color: theme.$tertiaryGrey;
      padding: 0px 4px;
      font-size: 12px;
    }
  }
}
