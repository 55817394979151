.accordion {
  &.isDisabled {
    pointer-events: none;
  }
  &__title {
    display: flex;
    cursor: pointer;
    align-items: center;
    grid-gap: 12px;
    &__end {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    &_icon {
      transform: rotate(0deg);
      transition: transform 0.35s linear;
      &.collapsed {
        transform: rotate(180deg);
        transition: transform 0.3s linear;
      }
    }
  }
  &__item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    padding-bottom: 5px;
    &.collapsed {
      max-height: 0;
      padding-bottom: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
  }
}
