.blurHashWrapper {
  height: 100%;
  width: 100%;
  position: relative;

  & > div {
    position: absolute !important;
    top: 0;
  }
  img {
    position: relative;
  }
}

.pictureTag {
  display: block;

  &.hasValidAttribution {
    position: relative;
  }
  img {
    display: block;
  }
}

.fitWidth {
  & > div {
    width: 100% !important;
    height: auto !important;
  }
  img {
    width: 100% !important;
    height: auto !important;
  }
}

.mediaAttribution {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.pictureWithAttribution {
  position: relative;
}
