@use 'styles/theme/theme' as theme;

.filterContainer {
  display: flex;
  gap: 12px;
  width: 100%;
  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid theme.$borderGrey;
    cursor: pointer;
    position: relative;
    background-color: theme.$orangeLight;

    &:hover {
      border: 1px solid theme.$orange;

      span {
        color: theme.$orange;
        text-decoration: underline;
      }
      svg {
        fill: theme.$orange;
      }
      .count {
        color: theme.$white;
        text-decoration: none;
      }
    }
    &:active {
      box-shadow: none;
      border: 1px solid theme.$orange;
      span {
        color: theme.$orange;
        text-decoration: none;
      }
      svg {
        fill: theme.$orange;
      }
      .count {
        color: theme.$white;
        text-decoration: none;
      }
    }

    &_icon {
      min-height: 12px;
      min-width: 12px;
    }
  }

  .count {
    margin: 0 6px;
    background-color: theme.$red;
    border-radius: 50%;
    margin-left: 3px;
    height: 16px;
    width: 16px;
    min-width: 16px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    color: theme.$white;
  }

  .truncateTwoLines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  .truncateOneLine {
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    display: block;
  }
}

.categoryName {
  & + & {
    margin-left: 7px;
    &::before {
      content: '|';
      margin-right: 7px;
    }
  }
}

.filterContainerDetailed {
  .filterContainer {
    &__box:nth-child(1) {
      width: 110px;
      min-width: 110px;
    }
    &__box:nth-child(2) {
      width: 200px;
      min-width: 200px;
    }
    &__box:nth-child(3) {
      width: 126px;
    }
    &__box:nth-child(4) {
      width: 180px;
    }
    &__box:nth-child(5) {
      width: 201px;
    }
    &__box:nth-child(6) {
      width: 201px;
    }
    &__box:nth-child(7) {
      width: 188px;
    }

    &__box:last-child {
      flex: 1;
    }
  }
}

@media screen and (max-width: 1000px) {
  .filterContainerDetailed {
    .filterContainer {
      &__box:nth-child(1) {
        width: 90px;
        min-width: unset;
      }
      &__box:nth-child(2) {
        min-width: unset;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .filterContainerDetailed {
    .filterContainer {
      &__box:nth-child(1) {
        width: 240px;
      }
      &__box:nth-child(2) {
        width: 128px;
      }
      &__box:nth-child(3) {
        width: 216px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .filterContainer {
    width: calc(100% - 12px);
    &__box:nth-child(1) {
      width: 50% !important;
    }
    &__box:nth-child(2) {
      width: 50% !important;
    }
    &__box:last-child {
      flex: 0;
    }
  }
}

.disableBtn {
  background-color: white;
  pointer-events: none;
}
