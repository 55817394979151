@use 'styles/theme/theme' as theme;

.root {
  width: 372px !important;
}

.content {
  padding: 0 16px;
}

.block {
  padding: 16px 0;

  & + & {
    border-top: 1px solid theme.$borderGrey;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    padding: 16px 0;
  }
  &__footer {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    justify-content: flex-end;
  }
}

.parameter {
  & + & {
    margin-top: 20px;
  }
}

.footer {
  text-align: center;
  padding: 16px;
}
