.root {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 10px;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}

.workRole {
  &__single {
    text-transform: uppercase;
    &_spacing30 {
      margin-left: 30px;
    }
  }
  &__multiple {
    p + p {
      margin-left: 12px;
    }
  }
}

.roles {
  &__operator {
    padding: 0 20px;
  }
}
