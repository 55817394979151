@use 'styles/theme/theme' as theme;

.filterOptions {
  display: flex;
  flex-direction: column;
  &__checkbox {
    padding: 6px 11px 6px 0;
    margin-top: 4px;
    align-items: flex-start;
    width: calc(100% + 11px);
    &_hasSubtext {
      align-items: flex-start;
    }
    span:last-child {
      flex: 1;
    }
    :global(.MuiCheckbox-root) {
      padding: 0 9px;
    }
    &:hover {
      background: theme.$blueLight6;
      span {
        color: theme.$blue;
      }
    }
  }

  &__radioItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1px;
    label {
      width: 100%;
    }
    &:hover {
      .rolesOptions__item_label {
        color: theme.$blue;
        text-decoration: underline;
        font-weight: theme.$font-weight-medium;
      }
      .rolesOptions__item_count {
        color: theme.$blue;
      }
    }

    span {
      display: flex;
      padding: 9px 0;
      cursor: pointer;
    }

    &_count {
      margin-left: 4px;
    }
  }

  &__radioInput {
    width: 14px !important;
    height: 14px !important;
  }

  &__radioWrapper {
    padding-left: 0 !important;
    input {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
    &:hover {
      background: transparent !important;
    }
  }

  &__nestedFilters {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    &_checkbox {
      width: calc(100% + 11px);
      padding: 6px 11px 6px 0px;
      margin-top: 4px;
      align-items: center;
      & > :last-child {
        flex: 1;
      }
      :global(.MuiCheckbox-root) {
        padding: 0 9px;
      }
      &:hover {
        background: theme.$blueLight6;
        span {
          color: theme.$blue;
        }
      }
    }

    &_label {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__checkboxSelected {
    span:last-child {
      color: theme.$blue;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    span:last-child {
      flex: 0;
    }
    &_total {
      margin-top: 3px;
    }
    &_name {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 3px;
      &:first-child {
        font-weight: 500;
        line-height: normal;
      }
    }
    &_nameSpacing {
      margin-top: 0px;
    }
  }
}

.clearSearch {
  padding: 8px 12px;
  color: theme.$secondaryGrey;
  cursor: pointer;
  &:hover {
    background-color: theme.$whiteSmoke;
  }
  &__disabled {
    opacity: 0.3;
  }
}

.filterDropdown {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
    position: relative;
    height: calc(100% - 52px);
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .clearSearch {
      margin: 0 4px 8px;
      justify-content: flex-start;
    }
    &.blur {
      filter: blur(5px);
      pointer-events: none;
    }
  }
  &__title {
    padding: 16px 16px 12px 16px;
    background-color: theme.$orangeLight;

    @media only screen and (max-width: 1024px) {
      padding: 20px 16px 6px 16px;
      background-color: theme.$white;
    }
  }
  &__header {
    display: flex;
    flex-direction: column-reverse;
    &_popover {
      flex-direction: column;
    }
    &_input {
      width: 100%;
      padding: 0px 12px 12px 10px;
    }
  }
  &__options {
    flex-grow: 1;
    padding: 4px 16px 52px 16px;
    overflow: auto;
    &_maxCountOptions {
      &_separator {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid theme.$borderGrey;
      }
      .topResultText {
        display: block;
        margin-bottom: 4px;
      }
    }
  }

  &__isSearchHidden {
    padding-top: 12px;
  }

  .allResultsText {
    margin: 0px 0 4px 0;
    display: block;
  }

  &__footer {
    border-top: 1px solid theme.$tertiaryGrey;
    padding: 8px 12px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    &_text {
      display: block;
      text-align: center;
      margin-bottom: 12px;
    }
    &_wrapper {
      display: flex;
      justify-content: space-between;
    }
    .icon {
      svg {
        fill: theme.$secondaryGrey !important;
      }
    }
    .iconActive {
      svg {
        fill: theme.$secondaryGrey !important;
      }
    }

    .submitSearch {
      &:hover {
        box-shadow: none;
      }
      &__disabled {
        opacity: 0.8;
      }
    }

    @media screen and (max-width: 1024px) {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 8px 16px;
      background-color: theme.$white;
      z-index: 2;
      gap: 9px;
      .clearSearch {
        padding: 8px 12px;
      }

      .submitSearch {
        flex-grow: 1;
      }
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 42px;
    }
  }

  &.filterDropdownInline {
    .filterDropdown__title {
      background-color: transparent;
    }
    .filterDropdown__footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 16px;
      background-color: theme.$white;
      z-index: 2;
      gap: 9px;
      .clearSearch {
        padding: 8px 12px;
      }

      .submitSearch {
        flex-grow: 1;
      }
    }
  }
}

.lockedFiltersModal {
  border-radius: 8px;
  border: 1px solid #ffbd99;
  background: linear-gradient(180deg, #fffdfc 0%, #ffe5d7 100%);
  position: absolute;
  width: 260px;
  max-width: calc(100% - 30px);
  margin: auto;
  top: calc((50vh - 60px - 50px) / 2); // NOTE: 50vh modal height, 60px header height, 50px footer height
  right: 16px;

  @media screen and (max-width: 1024px) {
    width: 200px;
    top: calc((50vh - 20px - 30px) / 2);
  }

  @media screen and (max-width: 400px) {
    width: 160px;
  }

  &__content {
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 8px;
    &_icon {
      display: flex;
      align-items: center;
      height: 26px;
      width: 26px;
      padding: 4px;
      border-radius: 50%;
      background-color: theme.$primaryGrey;
      svg {
        fill: theme.$white;
      }
    }
  }
  &__cta {
    &_primary {
      margin-top: 12px;
    }
  }
}

.roleProfessionOption {
  &__primaryLabel {
    display: block;
  }
}

.scrollLeftIndicator,
.scrollRightIndicator {
  cursor: pointer;
  height: 41px;
  margin-top: -1px;
  border: 1px solid theme.$borderGrey;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.scrollLeftIndicator {
  border: transparent;
  border-right: 1px solid theme.$secondaryGrey;
  box-shadow: 3px -0.5px 5px 0 rgba(0, 0, 0, 0.2);

  svg {
    margin-left: 7px;
  }
}

.orgTypesSelectAll {
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 16px;
  & > label {
    padding-top: 0;
  }
}

.orgTypes {
  display: flex;
  padding: 0 16px 12px 16px;
  & > div {
    gap: 8px;
  }
  .orgTypeOption {
    display: flex;
    padding: 6px 12px 6px 11px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 18px;
    border: 1px solid theme.$borderGrey;
    cursor: pointer;
    &:hover {
      border-radius: 18px;
      border: 1px solid theme.$blue;
      background: theme.$blueLight6;
      & > span {
        color: theme.$blue;
        text-decoration: underline;
      }
    }
    &__checked {
      border: 1px solid theme.$blue;
      background: theme.$blueLight6;
    }
    &__checkbox {
      padding-top: 0 !important;
      margin-left: 0 !important;
      & > span  {
        padding: 0 !important;
        & > svg {
          width: 14px !important;
          height: 14px !important;
        }
      }
    }
  }
}



